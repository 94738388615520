import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {  useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import parse from 'html-react-parser'
import { SelfCheckinHeader } from '@/components/molecules'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { CustomCheckin } from '@/components/organisms/self-checkin/custom-checkin'
import { AccommodationInput, NationalityType } from '@/models/self-checkin/accommodation-info'
import { addValidateGuidesRequired, changeValidateGuidesRequired } from '@/utils/guide'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { IdentityVerifyCamera } from '@/components/organisms/self-checkin/identity-verify/identity-verify-camera'
import { AccommodationForm } from '../../organisms/self-checkin/accommodation-form'

export const InfoEdit: React.FC<{}> = () => {
  const { t, i18n } = useTranslation()
  const [guides, setGuides] = useState<any>([])
  const [options, setOptions] = useState<any>()
  const [selectedInputValue, setSelectedInputValue] = useState<any>([])
  const { state, saveState } = useSelfCheckInState()
  const [accommodationInfo, setAccommodationInfo] = useState<AccommodationInput>(state.accommodationInfo!)
  const [isFormValidRequiredFields, setIsFormValidRequiredFields] = useState(true)
  const [validGuides, setValidGuides] = useState<any>([])
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [isPostCodeValid, setIsPostCodeValid] = useState(true)
  const [isLastNameValid, setIsLastNameValid] = useState(true)
  const [isFirstNameValid, setIsFirstNameValid] = useState(true)
  const [activateCameraType, setActivateCameraType] = useState<'front' | 'back' | 'representativePassport' | undefined>(undefined)
  const [identificationFrontImage, setIdentificationFrontImage] = useState<string | undefined>(
    state.uploadLicense?.identificationFrontImage,
  )
  const [identificationBackImage, setIdentificationBackImage] = useState<string | undefined>(state.uploadLicense?.identificationBackImage)
  const [representativePassportImage, setRepresentativePassportImage] = useState<string | undefined>(
    state.uploadLicense?.representativePassportImage,
  )
  const lang = i18n.language
  const history = useHistory()
  const [listValidateProps, setListValidateProps] = useState(['firstName', 'lastName'])

  const checkValidateFormRequired = () => {
    for (const property in accommodationInfo) {
      if (listValidateProps.includes(property)) {
        if (!accommodationInfo[property] || (typeof accommodationInfo[property] === 'string' && !accommodationInfo[property].trim())) {
          return false
        }
      }
    }
    if (lang === 'ja' && accommodationInfo.nationality === 'japanese' && state.setting.accommodation.requiredFuriganaInput) {
      if (!accommodationInfo.firstNameFurigana.trim() || !accommodationInfo.lastNameFurigana.trim()) {
        return false
      }
    }
    const validAccompany = accommodationInfo?.accompany?.filter(v => !v.lastName.trim() || !v.firstName.trim()) ?? []
    if (!accommodationInfo.hasContactForAllGuest && validAccompany?.length > 0) {
      return false
    }

    return true
  }

  const checkEmailValid = value => {
    const halfByteRegex = /^[!-~]+$/
    const mailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return halfByteRegex.test(value) && mailRegex.test(value)
  }

  const checkNumberOnly = value => {
    return /^[0-9]*$/.test(value)
  }

  const checkOnlyAnphabet = value => {
    return /^[a-zA-Z]*$/.test(value)
  }

  const isDisabled = (): boolean => {
    const isValid = checkValidateFormRequired()
    if (validGuides.length > 0 || !isValid) {
      return true
    }
    return false
  }

  const _checkBlank = () => {
    replaceInputValue()
    const nav = t('Information from the facility')
    const notes = guides.map(guide => {
      if (guide.inputType !== 'none') {
        return `${guide.jpTitle}:${guide.selectedAnswer}`
      }
    })
    const filteredNotes = notes.filter(v => v)
    let checkinMemo: string = ''
    if (filteredNotes.length !== 0) {
      checkinMemo = [nav, ...filteredNotes].join('\n')
    } else {
      checkinMemo = ''
    }
    onClickConfirm(checkinMemo)
  }
  // Replace form content with input content, not filled in with -
  const replaceInputValue = () => {
    const _guides = [...guides]
    _guides.map(guide => {
      selectedInputValue.map(v => {
        if (v.customCheckinId === guide.customCheckinId) {
          // When you reselect Please select
          if (v.value === '') {
            guide.selectedAnswer = '-'
          } else {
            guide.selectedAnswer = v.value
          }
        }
      })
      // when not choosing
      if (!guide.selectedAnswer) {
        guide.selectedAnswer = '-'
      }
    })
    setGuides(_guides)
  }

  const onClickConfirm = checkinMemo => {
    setIsEmailValid(true)
    setIsPhoneValid(true)
    setIsPostCodeValid(true)
    setIsFirstNameValid(true)
    setIsLastNameValid(true)
    const isValid = checkValidateFormRequired()
    setIsFormValidRequiredFields(isValid)
    if (!isValid) {
      return
    }
    const checkEmail = accommodationInfo.mail ? checkEmailValid(accommodationInfo.mail) : true
    const checkPhone = checkNumberOnly(accommodationInfo.phoneNumber)

    const checkLastName = !(accommodationInfo.nationality == 'japanese' && lang === 'ja')
      ? checkOnlyAnphabet(accommodationInfo.lastName)
      : true
    const checkFirstName = !(accommodationInfo.nationality === 'japanese' && lang === 'ja')
      ? checkOnlyAnphabet(accommodationInfo.firstName)
      : true

    setIsEmailValid(checkEmail)
    setIsPhoneValid(checkPhone)
    setIsFirstNameValid(checkFirstName)
    setIsLastNameValid(checkLastName)

    const isValidForm = checkEmail && checkPhone && checkFirstName && checkLastName
    if (!isValidForm) {
      // set the name of the error message
      let scrollName = ''
      switch (true) {
        case !checkLastName:
          scrollName = 'lastName'
          break
        case !checkFirstName:
          scrollName = 'firstName'
          break
        case !checkPhone:
          scrollName = 'phoneNumber'
          break
        case !checkEmail:
          scrollName = 'mail'
          break
        default:
          // scroll to top
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
          break
      }
      if (scrollName) {
        // get element by name
        const scrollErrorName = document.getElementsByName(scrollName)
        // Scroll to target element
        scrollErrorName[0]?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      return
    }

    if (accommodationInfo.hasContactForAllGuest) {
      accommodationInfo.accompany = []
    }
    saveState({
      ...state,
      checkinCustomize: state.checkinCustomize
        ? {
            ...state.checkinCustomize,
            checkinMemo,
          }
        : undefined,
      accommodationInfo,
    })

    const isForeign = accommodationInfo.nationality !== NationalityType.Jp
    if (!isForeign && state.setting.accommodation.requiredJapaneseIdentify) {
      if (!state.uploadLicense?.identificationFrontImage || !state.uploadLicense?.identificationBackImage) {
        history.replace({ pathname: '/self-checkin/identity-verify/upload-license' })
        return
      }
    }
    if (isForeign && state.setting.accommodation.requiredForeignIdentify) {
      if (!state.uploadLicense?.representativePassportImage) {
        history.replace({ pathname: '/self-checkin/identity-verify/upload-passport' })
        return
      }
    }
    history.goBack()
  }

  const onChangeState = (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>, customCheckinId) => {
    if (!e.target.value) {
      const addValidateGuides = addValidateGuidesRequired(customCheckinId, guides)
      if (addValidateGuides.length > 0) {
        const errorMessage = document.getElementById(addValidateGuides[0]['customCheckinId'] + '-error')
        if (errorMessage) {
          errorMessage.style.visibility = 'visible'
        }
      }
      setValidGuides(addValidateGuides.concat(validGuides))
    } else {
      const changeValidateGuides = changeValidateGuidesRequired(customCheckinId, validGuides)
      const errorMessage = document.getElementById(customCheckinId + '-error')
      if (errorMessage) {
        errorMessage.style.visibility = errorMessage.style.visibility === 'visible' ? 'hidden' : ''
      }
      setValidGuides(changeValidateGuides)
    }
    const value = e.target.value
    const name = e.target.name
    setSelectedInputValue([...selectedInputValue, { customCheckinId: customCheckinId, [name]: value }])
  }

  const getTitle = guide => {
    if (lang === 'ja') {
      return parse(guide.jpTitle)
    }
    if (lang === 'en') {
      return parse(guide.enTitle)
    }
    if (lang === 'ko') {
      return parse(guide.koTitle)
    }
    if (lang === 'zh') {
      return parse(guide.zhTitle)
    }
  }

  const getContent = guide => {
    if (lang === 'ja') {
      return parse(guide.jpContent)
    }
    if (lang === 'en') {
      return parse(guide.enContent)
    }
    if (lang === 'ko') {
      return parse(guide.koContent)
    }
    if (lang === 'zh') {
      return parse(guide.zhContent)
    }
  }

  const getOption = guide => {
    if (lang === 'ja') {
      return guide.jpInputValue
    }
    if (lang === 'en') {
      return guide.enInputValue
    }
    if (lang === 'ko') {
      return guide.koInputValue
    }
    if (lang === 'zh') {
      return guide.zhInputValue
    }
  }

  useEffect(() => {
    if (!state.accommodationInfo) {
      return
    }
    setAccommodationInfo(state.accommodationInfo)
    if (state.checkinCustomize) {
      setGuides(state.checkinCustomize.guides)
    }
    const addValidateProps: string[] = []
    if (state.setting.accommodation.requiredAccompanyNameInput) {
      addValidateProps.push('accompany')
    }
    if (state.setting.accommodation.requiredBirthDateInput) {
      addValidateProps.push('dateOfBirth')
    }
    if (state.setting.accommodation.requiredGenderInput) {
      addValidateProps.push('gender')
    }
    if (state.setting.accommodation.requiredTelephoneInput) {
      addValidateProps.push('phoneNumber')
    }
    if (state.setting.accommodation.requiredEmailInput) {
      addValidateProps.push('mail')
    }
    if (state.setting.accommodation.requiredAddressInput) {
      addValidateProps.push('address')
    }
    if (addValidateProps.length) {
      setListValidateProps([...listValidateProps, ...addValidateProps])
    }
  }, [])

  useEffect(() => {
    if (guides) {
      setOptions({})
      guides.map((guide, index) => {
        const translatedOption = getOption(guide)
        setOptions(prevOptions => ({
          ...prevOptions,
          [index]: translatedOption,
        }))
      })
    }
  }, [guides, lang])

  const onChangeAccommodationInfo = (name, value) => {
    if (listValidateProps.includes(name)) {
      if (typeof value === 'string' && !value.trim()) {
        setIsFormValidRequiredFields(false)
      }
    }
    if (
      (name === 'firstNameFurigana' || name === 'lastNameFurigana') &&
      lang === 'ja' &&
      accommodationInfo?.nationality === 'japanese' &&
      state.setting.accommodation.requiredFuriganaInput
    ) {
      if (!value.trim()) {
        setIsFormValidRequiredFields(false)
      }
    }

    setAccommodationInfo(accomInfo => ({ ...accomInfo, [name]: value }))
  }

  const onShutter = (image: any) => {
    if (activateCameraType === 'front') {
      setIdentificationFrontImage(image)
    } else if (activateCameraType === 'back') {
      setIdentificationBackImage(image)
    } else {
      setRepresentativePassportImage(image)
    }
    setActivateCameraType(undefined)
  }

  if (!state?.hotelId) {
    history.replace({ pathname: '/dashboard' })
    return <></>
  }

  return (
    <>
      {!activateCameraType && (
        <div css={containerStyle}>
          <SelfCheckinHeader goToPreviousPage={history.goBack} title={t('Please confirm the contents')} />
          <div css={mainStyle}>
            <div css={guestFormWrapperStyle}>
              <AccommodationForm
                accommodationInfo={accommodationInfo}
                onChange={onChangeAccommodationInfo}
                requiredAccompanyNameInput={state.setting.accommodation.requiredAccompanyNameInput}
                requiredFuriganaInput={state.setting.accommodation.requiredFuriganaInput}
                requiredBirthDateInput={state.setting.accommodation.requiredBirthDateInput}
                requiredGenderInput={state.setting.accommodation.requiredGenderInput}
                requiredTelephoneInput={state.setting.accommodation.requiredTelephoneInput}
                requiredEmailInput={state.setting.accommodation.requiredEmailInput}
                requiredAddressInput={state.setting.accommodation.requiredAddressInput}
                isFormValidRequiredFields={isFormValidRequiredFields}
                imageInfo={{
                  identificationBackImage,
                  identificationFrontImage,
                  representativePassportImage,
                  uploadImage: setActivateCameraType,
                }}
                isEmailValid={isEmailValid}
                isPhoneValid={isPhoneValid}
                isPostCodeValid={isPostCodeValid}
                isFirstNameValid={isFirstNameValid}
                isLastNameValid={isLastNameValid}
              />
            </div>
            {guides && !!guides?.length && (
              <div css={guidesInnerStyle}>
                <div css={descriptionStyle}>
                  <p>{t('Guidance')}</p>
                </div>
                {guides.map(
                  (guide, index) =>
                    guide.inputType !== 'none' && (
                      <CustomCheckin
                        key={index}
                        index={index}
                        guide={guide}
                        options={options}
                        selectedInputValue={selectedInputValue}
                        onChangeState={onChangeState}
                        getTitle={getTitle}
                        getContent={getContent}
                        isEdit={true}
                      />
                    ),
                )}
              </div>
            )}
            <SelfCheckinFooter isNext={'save'} isDisabled={isDisabled} isDisabledButton={true} goToNextPage={_checkBlank} />
          </div>
        </div>
      )}
      {activateCameraType && (
        <IdentityVerifyCamera
          onCancel={() => setActivateCameraType(undefined)}
          onShutter={onShutter}
          type={activateCameraType !== 'representativePassport' ? 'license' : 'passport'}
          side={activateCameraType !== 'representativePassport' ? activateCameraType : undefined}
        />
      )}
    </>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  backgroundColor: '#F2F2F2',
  '*': {
    fontFamily: 'Noto Sans JP',
  },
})

const mainStyle = css({
  padding: '14px 0 32px ',
})

const guidesInnerStyle = css({
  maxWidth: 874,
  backgroundColor: '#fff',
  margin: '0 auto 80px',
  borderRadius: 5,
})

const descriptionStyle = css({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  padding: '40px 0 10px 0',
  p: {
    fontSize: 21,
    lineHeight: '27px',
    letterSpacing: '1.8px',
    textAlign: 'center',
    fontWeight: 'bold',
    '@media(max-width: 1080px)': {
      fontSize: 24,
    },
  },
})

const guestFormWrapperStyle = css({
  maxWidth: 874,
  margin: '86px auto 20px',
})
