import React, { useState } from 'react'
import { css } from '@emotion/core'
import { RequireText } from '@/components/atoms/require-text'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { TimeSelectionType } from '@/models/self-checkin/custom-checkin'


interface TimeSelectionProps {
  timeSelection: TimeSelectionType
  onChange: ({ timeSelectionId, selectedTime, day }: { timeSelectionId: string; selectedTime: string; day: string }) => void
}

export const TimeSelection: React.FC<TimeSelectionProps> = ({ timeSelection, onChange }) => {
  const [selectedTime, setSelectedTime] = useState<{ [date: string]: string }>({})

  const { i18n } = useTranslation()

  const onChangeTime = (e: React.ChangeEvent<HTMLInputElement>, day: string) => {
    setSelectedTime({ ...selectedTime, [day]: e.target.value })
    onChange({ timeSelectionId: timeSelection.id, selectedTime: e.target.value, day })
  }

  const createDateLabel = ({ date, checkinDate, checkoutDate }: { date: string; checkinDate: string; checkoutDate: string }) => {
    const targetDay = dayjs(date)
    const checkin = dayjs(checkinDate)
    const checkout = dayjs(checkoutDate)

    if (targetDay.isSame(checkout, 'day')) {
      return '最終泊'
    }

    const currentNight = targetDay.diff(checkin, 'day') + 1

    if (currentNight === 1) {
      return '1泊目'
    } else {
      return `${currentNight}泊目`
    }
  }

  return (
    <div key={timeSelection.title} css={timeSelectionStyle}>
      <h2 className="title">
        {timeSelection.title[i18n.language]} {timeSelection.isRequired && <RequireText />}
      </h2>
      <p className="description" dangerouslySetInnerHTML={{ __html: timeSelection.description[i18n.language] }} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
        {Object.keys(timeSelection.availabilityTime).map(date => {
          const availabilityTime = timeSelection.availabilityTime[date]
          return (
            <div>
              <h3 className="sub-title">
                {createDateLabel({ date, checkinDate: timeSelection.checkinDate, checkoutDate: timeSelection.checkoutDate })}
              </h3>
              <RadioGroupField
                value={selectedTime[date]}
                items={Object.keys(timeSelection.availabilityTime[date])
                  .filter(time => availabilityTime[time] > 0)
                  .map(time => {
                    return {
                      value: time,
                      label: time,
                    }
                  })}
                itemWrapperCss={css({
                  border: '1px solid #CCCCCC',
                  borderRadius: 10,
                  fontSize: 21,
                  padding: '15px 24px',
                })}
                groupCss={{ gap: 24 }}
                style={{ direction: 'wrap' }}
                onChange={e => onChangeTime(e, date)}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const timeSelectionStyle = css({
  '.title': {
    marginBottom: 16,
    fontSize: 18,
    fontWeight: 'bold',
  },
  '.description': {
    marginBottom: 32,
    lineHeight: 1.5,
  },
  '.sub-title': {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 16,
  },
})
