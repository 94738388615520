import React, { useContext } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Signin } from '@/components/pages/signin'
import { Dashboard } from '@/components/pages/dashboard'
import { ReservationDetail } from '@/components/pages/accommodation-management/reservation/detail'
import { AccommodationManagement } from '@/components/pages/accommodation-management/list'
import { CustomerList } from '@/components/pages/customer-manager/list'
import { CheckinDetail } from '@/components/pages/accommodation-management/checkin/detail'
import { StayDetail } from '@/components/pages/accommodation-management/stay/detail'
import { CheckoutDetail } from '../pages/accommodation-management/checkout/detail'
import { EntireSearch } from '../pages/accommodation-management/entire-search'
import { UploadHistory } from '@/components/pages/accommodation-management/upload-history/upload-history'
import { DownloadHistory } from '@/components/pages/accommodation-management/download-history/download-history'
import { ReceiptHistory } from '@/components/pages/sales-manager/receipt-history'
import { CustomerDetail } from '@/components/pages/customer-manager/detail'
import { RoomManagerDaily } from '@/components/pages/room-manager/daily'
import { RoomManagerIndicator } from '@/components/pages/room-manager/indicator'
import { RequestManager } from '@/components/pages/request-manager'
import { CheckinPayment } from '@/components/pages/self-checkin/payment'
import { SalesManagerDetail } from '@/components/pages/sales-manager/sales/detail'
import { SalesManagerDetailList } from '@/components/pages/sales-manager/sales/detail-list'
import { SalesManagerBusinessForms } from '@/components/pages/sales-manager/business-forms'
import { OperationLog } from '@/components/pages/operation-log'
import { SettingAccount } from '@/components/pages/setting/account'
import { SettingAccountEdit } from '@/components/pages/setting/account/edit'
import { SettingAroundSpot } from '@/components/pages/setting/around-spot/around-spot'
import { SettingTopPage } from '@/components/pages/setting/top-page'
import { SettingAccountReceiptEdit } from '@/components/pages/setting/account/receipt-edit'
import { SettingAccountCompanyInfoEdit } from '@/components/pages/setting/account/company-info-edit'
import { SettingFacilityList } from '@/components/pages/setting/facility/list/list'
import { SettingFacilityBasicEdit } from '@/components/pages/setting/facility/basic/edit'
import { SettingFacilityWifiEdit } from '@/components/pages/setting/facility/wifi/edit'
import { SettingFacilityMealEdit } from '@/components/pages/setting/facility/meal/edit'
import { SettingFacilityBathEdit } from '@/components/pages/setting/facility/bath/edit'
import { SettingFacilityEquipmentEdit } from '@/components/pages/setting/facility/equipment/edit'
import { SettingFacilityFloorEdit } from '@/components/pages/setting/facility/floor/edit'
import { SettingFacilityAccessEdit } from '@/components/pages/setting/facility/access/edit'
import { SettingFacilityGuideEdit } from '@/components/pages/setting/facility/guide/edit'
import { SettingFacilityTermsEdit } from '@/components/pages/setting/facility/terms/edit'

import { SettingGuestRoomNumber } from '@/components/pages/setting/guest-room/room/guest-room-number'
import { SettingGuestRoomType } from '@/components/pages/setting/guest-room/room/guest-room-type'
import { SettingGuestRoomFloor } from '../pages/setting/guest-room/room/guest-room-floor'
import { SettingGuestRoomAutoAssign } from '../pages/setting/guest-room/room/guest-room-auto-assign'
import { SettingGuestRoomKey } from '@/components/pages/setting/guest-room/key-number/index'

import { SettingSalesManagerSales } from '@/components/pages/setting/sales-manager/sales'
import { SettingSalesManagerPayment } from '@/components/pages/setting/sales-manager/payment/list'
import { SettingSalesManagerRegister } from '@/components/pages/setting/sales-manager/register/list'
import { SettingSalesManagerDiscount } from '@/components/pages/setting/sales-manager/discount/list'
import { SettingSalesManagerSubjectSet } from '@/components/pages/setting/sales-manager/subject-set'
import { SettingSalesManagerPaymentEdit } from '@/components/pages/setting/sales-manager/payment/edit'
import { SettingSalesManagerRegisterEdit } from '@/components/pages/setting/sales-manager/register/register-edit'
import { SettingSalesManagerDiscountEdit } from '@/components/pages/setting/sales-manager/discount/edit'
import { SettingCustomCheckin } from '@/components/pages/setting/custom-checkin'
import { SettingCustomCheckinEdit } from '@/components/pages/setting/custom-checkin/edit'
import { SettingSelfCheckin } from '@/components/pages/setting/self-checkin'
import { SettingPrinterSelfCheckin } from '@/components/pages/setting/printer/self-checkin'
import { SettingPrinterRequest } from '@/components/pages/setting/printer/request'
import { SettingMobileRequest } from '@/components/pages/setting/mobile-request'
import { SettingReservationMarketSegment } from '@/components/pages/setting/reservation/market-segment'
import { SettingReservationMarketSegmentEdit } from '@/components/pages/setting/reservation/market-segment/edit'
import { SettingReservationChannelCode } from '@/components/pages/setting/reservation/channel-code'
import { SettingReservationChannelCodeEdit } from '@/components/pages/setting/reservation/channel-code/edit'
import { SettingPayment } from '@/components/pages/setting/setting-payment'
import { SettingCoupons } from '@/components/pages/setting/setting-coupons'
import { SettingStaff } from '@/components/pages/setting/setting-staff'
import { SettingCleaningManager } from '@/components/pages/setting/cleaning-manager'
import { CheckList } from '../pages/setting/cleaning-manager/check-list'

import { SelectLanguage } from '@/components/pages/self-checkin'
import { TabletRegisterPage } from '@/components/pages/tablet-register'
import { SelfCheckinComplete } from '@/components/pages/self-checkin/complete'
import { SettingFacilityCheckinOutEdit } from '@/components/pages/setting/facility/checkin-out/edit'

import { QrScan } from '@/components/pages/qr-checkin/scan'
import { QrCamera } from '@/components/pages/qr-checkin/camera'
import { QrComplete } from '@/components/pages/qr-checkin/complete'
import { SettingSalesManagerAccountsReceivable } from '../pages/setting/sales-manager/accounts-receivable/list'
import { SettingSalesManagerAccountsReceivableEdit } from '../pages/setting/sales-manager/accounts-receivable/edit'
import MobileMenu from '@/components/pages/mobile-menu'
import { CleaningManager as CleaningManagerForCleaner } from '@/components/pages/cleaning-manager/cleaner'
import { CleaningManager } from '@/components/pages/cleaning-manager'
import { CouponsManager } from '@/components/pages/coupons-manager'
import { ReportsManager } from '@/components/pages/reports-manager'
import { SearchReservation } from '../pages/self-checkin/search-reservation'
import { SelectReservation } from '../pages/self-checkin/select-reservation'
import { AccommodationInfo } from '../pages/self-checkin/accommodation-info'
import { CheckinCustomize } from '../pages/self-checkin/checkin-customize'
import { UploadLicense as SelfCheckinUploadLicense } from '../pages/self-checkin/identity-verify/upload-license'
import { UploadPassport as SelfCheckinUploadPassport } from '../pages/self-checkin/identity-verify/upload-passport'
import { Index as SelfCheckinConfirm } from '../pages/self-checkin/confrim'
import { InfoEdit as SelfCheckinInfoEdit } from '../pages/self-checkin/info-edit'
import { AdvanceMoney } from '../pages/sales-manager/advance-money'

import { AuthContext } from '@/contexts/auth'
import { AccountContext } from '@/contexts/account'
import { hasCleanerRole, hasStaffRole } from '@/libs/account'
import Media from 'react-media'
import * as NewCheckinSetting from '../pages/setting/checkin'
import { SettingGuestAppAsset } from '../pages/setting/guest-app/asset'
import { SettingGuestAppAssetDetail } from '../pages/setting/guest-app/asset-detail'
import { SettingGuestAppDesign } from '../pages/setting/guest-app/design'
import { SettingMailDelivery } from '../pages/setting/mail-delivery'
import { SettingReplyTo } from '../pages/setting/mail-delivery/reply-to'
import { MailDeliveryContentNew } from '../pages/setting/mail-delivery/content/new'
import { MailDeliveryContentEdit } from '../pages/setting/mail-delivery/content/edit'
import { MailDeliveryHistoryList } from '../pages/mail-delivery-history'
import { SettingSalesManagerDepartment } from '../pages/setting/sales-manager/department'
import { SettingSalesManagerSubSubject } from '../pages/setting/sales-manager/sub-subject'
import { SpaceManager } from '../pages/space-manager'
import { SmartKeySpace } from '../pages/setting/smart-key/space'
import { InvoiceManagerList } from '../pages/invoice-manager/list'
import { SalesManagerDashboard } from '../pages/sales-manager/sales/dashboard'
import { SalesManagerAccounting } from '../pages/sales-manager/accounting'
import { CustomerManager } from '../pages/setting/customer-manager'
import { CustomerDownloadHistory } from '../pages/customer-manager/download-history'
import { Schedule as CleaningManagerSchedule } from '../pages/cleaning-manager/schedule'
import { CleaningManagerDetail } from '../pages/cleaning-manager/cleaner/detail'
import { CleaningManagerScheduleForCleaner } from '../pages/cleaning-manager/cleaner/schedule'
import { SettingSalesManagerTax } from '../pages/setting/sales-manager/tax'
import { SelfCheckinIssueRoomKey } from '../pages/self-checkin/issue-room-key'
import { SettingReservationPlan } from '../pages/setting/reservation/plan'
import { SelfCheckinPaymentTerminalWithCard } from '../pages/self-checkin/payment/terminal-with-card'
import { SelfCheckinPaymentTerminalWithQr } from '../pages/self-checkin/payment/terminal-with-qr'
import { SmartCheckinQrReader } from '../pages/self-checkin/smart-checkin-qr-reader'

export const Router: React.FC = () => {
  const { account } = useContext<any>(AccountContext)
  const { auth } = useContext<any>(AuthContext)

  const selfCheckinRouteMap = () => {
    const routes: Array<{ path: string; component: React.FC<any> }> = [
      { path: '/self-checkin', component: SelectLanguage },
      { path: '/self-checkin/select-reservation', component: SelectReservation },
      { path: '/self-checkin/search-reservation', component: SearchReservation },
      { path: '/self-checkin/qr-checkin-camera', component: SmartCheckinQrReader },
      { path: '/self-checkin/accommodation-info', component: AccommodationInfo },
      { path: '/self-checkin/checkin-customize', component: CheckinCustomize },
      { path: '/self-checkin/identity-verify/upload-license', component: SelfCheckinUploadLicense },
      { path: '/self-checkin/identity-verify/upload-passport', component: SelfCheckinUploadPassport },
      { path: '/self-checkin/confirm', component: SelfCheckinConfirm },
      { path: '/self-checkin/info/edit', component: SelfCheckinInfoEdit },
      { path: '/self-checkin/payment', component: CheckinPayment },
      { path: '/self-checkin/payment/terminal/card', component: SelfCheckinPaymentTerminalWithCard },
      { path: '/self-checkin/payment/terminal/qr', component: SelfCheckinPaymentTerminalWithQr },
      { path: '/self-checkin/issue-room-key', component: SelfCheckinIssueRoomKey },
      { path: '/self-checkin/complete', component: SelfCheckinComplete },
    ]
    return routes.map(r => <Route exact key={r.path} {...r} />)
  }

  return (
    <BrowserRouter>
      {!auth && (
        <Switch>
          {selfCheckinRouteMap()}
          <Route exact path="/" component={Signin} />
          <Route exact path="/qr-checkin" component={Signin} />
          <Redirect to="/" />
        </Switch>
      )}
      {auth && hasCleanerRole(account) && (
        <Switch>
          <Route exact path="/cleaning" component={CleaningManagerForCleaner} />
          <Route exact path="/cleaning/schedule" component={CleaningManagerScheduleForCleaner} />
          <Route exact path="/cleaning/detail/:roomId" component={CleaningManagerDetail} />
          <Redirect to="/cleaning" />
        </Switch>
      )}
      {auth && hasStaffRole(account) && (
        <Media query="(max-width: 599px)">
          {matches =>
            matches ? (
              <Switch>
                <Route exact path="/mobile-menu" component={MobileMenu} />
                <Route exact path="/qr-checkin" component={QrScan} />
                <Route exact path="/qr-checkin/scan" component={QrScan} />
                <Route exact path="/qr-checkin/camera" component={QrCamera} />
                <Route exact path="/qr-checkin/complete" component={QrComplete} />
                {selfCheckinRouteMap()}
                <Route exact path="/tablet-register" component={TabletRegisterPage} />
                <Redirect to="/mobile-menu" />
              </Switch>
            ) : (
              <Switch>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/reservation/:id" component={ReservationDetail} />
                <Route exact path="/accommodation-management" component={AccommodationManagement} />
                <Route exact path="/checkin/:id" component={CheckinDetail} />
                <Route exact path="/stay/:id" component={StayDetail} />
                <Route exact path="/checkout/:id" component={CheckoutDetail} />
                <Route exact path="/entire-search" component={EntireSearch} />
                <Route exact path="/csv-upload-history/list" component={UploadHistory} />
                <Route exact path="/csv-download-history/list" component={DownloadHistory} />
                <Route exact path="/sales-manager/receipt-history/list" component={ReceiptHistory} />
                <Route exact path="/customer/list" component={CustomerList} />
                <Route exact path="/customer/list/csv-download-history" component={CustomerDownloadHistory} />
                <Route exact path="/customer/:id" component={CustomerDetail} />
                <Route exact path="/room-manager/daily" component={RoomManagerDaily} />
                <Route exact path="/room-manager/indicator" component={RoomManagerIndicator} />
                <Route exact path="/sales-manager" component={SalesManagerDashboard} />
                <Route exact path="/sales-manager/detail" component={SalesManagerDetail} />
                <Route exact path="/sales-manager/detail-list" component={SalesManagerDetailList} />
                <Route exact path="/request-manager" component={RequestManager} />
                <Route exact path="/space-manager" component={SpaceManager} />
                <Route exact path="/sales-manager/business-forms" component={SalesManagerBusinessForms} />
                <Route exact path="/sales-manager/advance-money" component={AdvanceMoney} />
                <Route exact path="/sales-manager/sales-accounting" component={SalesManagerAccounting} />
                <Route exact path="/operation-log" component={OperationLog} />
                <Route exact path="/mail-delivery-history" component={MailDeliveryHistoryList} />
                <Route exact path="/setting/account" component={SettingAccount} />
                <Route exact path="/setting/account/edit" component={SettingAccountEdit} />
                <Route exact path="/setting/account/receipt-edit" component={SettingAccountReceiptEdit} />
                <Route exact path="/setting/account/company-info-edit" component={SettingAccountCompanyInfoEdit} />
                <Route exact path="/setting/top-page" component={SettingTopPage} />
                <Route exact path="/setting/around-spot" component={SettingAroundSpot} />
                <Route exact path="/setting/facility/list" component={SettingFacilityList} />
                <Route exact path="/setting/facility/checkin-out/edit" component={SettingFacilityCheckinOutEdit} />
                <Route exact path="/setting/facility/basic/edit" component={SettingFacilityBasicEdit} />
                <Route exact path="/setting/facility/wifi/edit" component={SettingFacilityWifiEdit} />
                <Route exact path="/setting/facility/meal/edit" component={SettingFacilityMealEdit} />
                <Route exact path="/setting/facility/bath/edit" component={SettingFacilityBathEdit} />
                <Route exact path="/setting/facility/equipment/edit" component={SettingFacilityEquipmentEdit} />
                <Route exact path="/setting/facility/floor/edit" component={SettingFacilityFloorEdit} />
                <Route exact path="/setting/facility/access/edit" component={SettingFacilityAccessEdit} />
                <Route exact path="/setting/facility/guide/edit" component={SettingFacilityGuideEdit} />
                <Route exact path="/setting/facility/terms/edit" component={SettingFacilityTermsEdit} />
                <Route exact path="/setting/guest-room-number" component={SettingGuestRoomNumber} />
                <Route exact path="/setting/guest-room-type" component={SettingGuestRoomType} />
                <Route exact path="/setting/guest-room-floor" component={SettingGuestRoomFloor} />
                <Route exact path="/setting/guest-room-auto-assign" component={SettingGuestRoomAutoAssign} />
                <Route exact path="/setting/guest-room-key" component={SettingGuestRoomKey} />
                <Route exact path="/setting/sales-manager/department" component={SettingSalesManagerDepartment} />
                <Route exact path="/setting/sales-manager/sales" component={SettingSalesManagerSales} />
                <Route exact path="/setting/sales-manager/sub-subject" component={SettingSalesManagerSubSubject} />
                <Route exact path="/setting/sales-manager/payment" component={SettingSalesManagerPayment} />
                <Route exact path="/setting/sales-manager/register" component={SettingSalesManagerRegister} />
                <Route exact path="/setting/sales-manager/discount" component={SettingSalesManagerDiscount} />
                <Route exact path="/setting/sales-manager/subject-set" component={SettingSalesManagerSubjectSet} />
                <Route exact path="/setting/sales-manager/payment/edit" component={SettingSalesManagerPaymentEdit} />
                <Route exact path="/setting/sales-manager/register/edit" component={SettingSalesManagerRegisterEdit} />
                <Route exact path="/setting/sales-manager/discount/edit" component={SettingSalesManagerDiscountEdit} />
                <Route exact path="/setting/sales-manager/accounts-receivable" component={SettingSalesManagerAccountsReceivable} />
                <Route exact path="/setting/sales-manager/accounts-receivable/edit" component={SettingSalesManagerAccountsReceivableEdit} />
                <Route exact path="/setting/sales-manager/tax" component={SettingSalesManagerTax} />
                <Route exact path="/setting/mobile-request" component={SettingMobileRequest} />
                <Route exact path="/setting/custom-checkin" component={SettingCustomCheckin} />
                <Route exact path="/setting/custom-checkin/edit" component={SettingCustomCheckinEdit} />
                <Route exact path="/setting/self-checkin" component={SettingSelfCheckin} />
                <Route exact path="/setting/printer/self-checkin" component={SettingPrinterSelfCheckin} />
                <Route exact path="/setting/printer/request" component={SettingPrinterRequest} />
                <Route exact path="/setting/reservation/channel-code" component={SettingReservationChannelCode} />
                <Route exact path="/setting/reservation/channel-code/edit" component={SettingReservationChannelCodeEdit} />
                <Route exact path="/setting/reservation/market-segment" component={SettingReservationMarketSegment} />
                <Route exact path="/setting/reservation/plan" component={SettingReservationPlan} />
                <Route exact path="/setting/reservation/market-segment/edit" component={SettingReservationMarketSegmentEdit} />
                <Route exact path="/setting/payment" component={SettingPayment} />
                <Route exact path="/setting/coupons" component={SettingCoupons} />
                <Route exact path="/setting/staff" component={SettingStaff} />
                <Route exact path="/setting/cleaning-manager" component={SettingCleaningManager} />
                <Route exact path="/setting/cleaning-manager/check-list" component={CheckList} />
                <Route exact path="/setting/customer-manager" component={CustomerManager} />
                <Route exact path="/setting/mail-delivery" component={SettingMailDelivery} />
                <Route exact path="/setting/mail-delivery-content/new" component={MailDeliveryContentNew} />
                <Route exact path="/setting/mail-delivery-content/:id" component={MailDeliveryContentEdit} />
                <Route exact path="/setting/reply-to" component={SettingReplyTo} />
                <Route exact path="/setting/guest-app/asset" component={SettingGuestAppAsset} />
                <Route exact path="/setting/guest-app/asset/:id" component={SettingGuestAppAssetDetail} />
                <Route exact path="/setting/guest-app/asset/:id/map-category" component={SettingGuestAppAssetDetail} />
                <Route exact path="/setting/guest-app/asset/:id/request-category" component={SettingGuestAppAssetDetail} />
                <Route exact path="/setting/guest-app/asset/:id/request-tag" component={SettingGuestAppAssetDetail} />
                <Route exact path="/setting/guest-app/asset/:id/request-sales-hour" component={SettingGuestAppAssetDetail} />
                <Route exact path="/setting/guest-app/asset/:id/request-notification" component={SettingGuestAppAssetDetail} />
                <Route exact path="/setting/guest-app/design" component={SettingGuestAppDesign} />
                <Route exact path="/setting/checkin/self-checkin" component={NewCheckinSetting.SelfCheckin} />
                <Route exact path="/setting/checkin/card-reader" component={NewCheckinSetting.SelfCheckinCardReader} />
                <Route exact path="/setting/checkin/terminal" component={NewCheckinSetting.SelfCheckinTerminal} />
                <Route exact path="/setting/checkin/custom-checkin" component={NewCheckinSetting.CustomCheckin} />
                <Route exact path="/setting/checkin/time-selection" component={NewCheckinSetting.TimeSelection} />
                <Route exact path="/setting/checkin/time-selection/new" component={NewCheckinSetting.TimeSelectionDetailPage} />
                <Route exact path="/setting/checkin/time-selection/:id" component={NewCheckinSetting.TimeSelectionDetailPage} />
                <Route exact path="/setting/checkin/custom-checkin/new" component={NewCheckinSetting.CustomCheckinEdit} />
                <Route exact path="/setting/checkin/custom-checkin/:id" component={NewCheckinSetting.CustomCheckinEdit} />
                <Route exact path="/setting/smart-key/room" component={SettingGuestRoomKey} />
                <Route exact path="/setting/smart-key/space" component={SmartKeySpace} />
                <Route exact path="/qr-checkin" component={QrScan} />
                <Route exact path="/qr-checkin/scan" component={QrScan} />
                <Route exact path="/qr-checkin/camera" component={QrCamera} />
                <Route exact path="/qr-checkin/complete" component={QrComplete} />
                {selfCheckinRouteMap()}
                <Route exact path="/cleaning-manager/status" component={CleaningManager} />
                <Route exact path="/cleaning-manager/schedule" component={CleaningManagerSchedule} />
                <Route exact path="/coupons-manager" component={CouponsManager} />
                <Route exact path="/reports-manager" component={ReportsManager} />
                <Route exact path="/tablet-register" component={TabletRegisterPage} />
                <Route exact path="/invoice-manager" component={InvoiceManagerList} />
                <Redirect to="/dashboard" />
              </Switch>
            )
          }
        </Media>
      )}
    </BrowserRouter>
  )
}
