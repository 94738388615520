import React from 'react'
import { css } from '@emotion/core'
import Lottie from 'react-lottie'

const animationData = require('@/static/images/json/loading.json')

export const ProgressDialog: React.FC<{}> = () => {
  return (
    <>
      <div css={backgroundStyle}></div>
      <div css={dialogStyle}>
        <div className="content-block">
          <Lottie height="50px" width="50px" options={{ animationData, autoplay: true, loop: true }} />
          <div className="-text" style={{ paddingRight: 16 }}>
            センター問い合わせ中
          </div>
        </div>
      </div>
    </>
  )
}

const backgroundStyle = css({
  background: 'rgba(0, 0, 0, 0.5)',
  height: '100vh',
  width: '100vw',
  position: 'absolute',
  zIndex: 1000,
  top: 0,
  left: 0,
})
const dialogStyle = css({
  position: 'absolute',
  zIndex: 1001,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: '#FFFFFF',
  borderRadius: 5,
  '.content-block': {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
  },
  '.-text': {
    fontSize: 21,
    fontWeight: 'bold',
    letterSpacing: 1.05,
    color: '#272727',
  },
})
