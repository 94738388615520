import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SelfCheckinHeader } from '@/components/molecules/self-checkin/header'
import { SupportQr } from '@/components/organisms/self-checkin/payment/support-qr'
import { ProgressDialog } from '@/components/organisms/self-checkin/payment/progress-dialog'
import QrReader from 'react-qr-reader'
import { SuccessDialog } from '@/components/organisms/self-checkin/payment/success-dialog'
import { ErrorDialog } from '@/components/organisms/self-checkin/payment/error-dialog'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import * as vescajs from '@/apis/vescajs'
import { useGuestAppUrl } from '@/hooks/use-guest-app-url'

export type CardInfo = {
  cardNumber: string
  expiredMonth: string
  expiredYear: string
  holderName: string
  cvc: string
}

export const SelfCheckinPaymentTerminalWithQr: React.FC = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { state, doCheckIn } = useSelfCheckInState()
  const [isStopScan, setIsStopScan] = useState(false)
  const [vesca, setVesca] = useState<vescajs.VescaJs>()
  const [receiveTerminalStatus, setReceiveTerminalStatus] = useState<'progress' | 'success' | 'error'>()
  const { mypageUrl, homePageUrl } = useGuestAppUrl({
    hotelId: state.hotelId,
    plugin: state.setting.plugin,
  })

  const onSuccess = async (event: vescajs.OutputCompleteEvent) => {
    setReceiveTerminalStatus('success')
    await doCheckIn({
      state,
      language: i18n.language,
      guestAppUrl: { mypageUrl, homePageUrl },
      selectedPaymentMethod: 'CREDIT_CARD',
      paymentResult: {
        orderId: `${event.SequenceNumber}`,
        cardBrand: event.CurrentService,
        amount: event.SettledAmount,
        totalAmountAt10Per: 0,
        totalAmountAt8Per: 0,
        totalTaxAt10Per: 0,
        totalTaxAt8Per: 0,
      },
    })
    history.replace({ pathname: '/self-checkin/issue-room-key' })
  }

  const onScan = (source: string | null) => {
    if (source === null || isStopScan) {
      return
    }
    setIsStopScan(true)
    vesca!.doQrPay({ amount: 2, tax: 0, qrCode: source })
    setReceiveTerminalStatus('progress')
  }

  useEffect(() => {
    const vescaJs = new vescajs.VescaJs(state.hotelId, state.selectReservation?.selectedReservationIds || [], {
      onCancel: () => {
        setReceiveTerminalStatus(undefined)
        history.goBack()
      },
      onError: () => {
        setReceiveTerminalStatus('error')
        setTimeout(() => history.goBack(), 5000)
      },
      onSuccess,
    })
    setVesca(vescaJs)

    return () => {
      if (vescaJs && vescaJs.inProgress) {
        vescaJs.doCancel()
      }
    }
  }, [])

  return (
    <>
      <SelfCheckinHeader goToPreviousPage={() => history.goBack()} title={t('Payment details')} />
      <div css={containerStyle}>
        <div style={{ maxWidth: 880 }}>
          <div className="operation-block">
            <div>コードをカメラにかざしてください</div>
          </div>

          <div className="terminal-and-brand-block">
            <div style={{ width: '50%', marginRight: 12 }}>
              <div className="terminal-image-block">
                <img src={require('@/static/images/self-checkin-payment/terminal/image_03.png')} />
              </div>
              <SupportQr className="brand-image-block" />
            </div>
            <div className="camera-block" style={{ width: '50%', marginLeft: 12 }}>
              <QrReader showViewFinder={false} onScan={onScan} onError={() => {}} constraints={{ facingMode: 'user' }} />
              <div className="camera-overlay">
                <div className="operation-message">QRを枠内に合わせてください</div>
                <canvas className="image-frame" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {receiveTerminalStatus === 'progress' && <ProgressDialog />}
      {receiveTerminalStatus === 'success' && <SuccessDialog />}
      {receiveTerminalStatus === 'error' && <ErrorDialog />}
    </>
  )
}

const containerStyle = css({
  backgroundColor: '#f2f2f2',
  display: 'flex',
  justifyContent: 'center',
  // add header size (=80)
  paddingTop: 32 + 80,
  paddingBottom: 32,
  height: '100vh',
  width: '100%',
  '*': { fontFamily: 'Noto ans JP', color: '#272727' },
  '.operation-block': {
    textAlign: 'center',
    'div:first-of-type': {
      fontSize: 21,
      lineHeight: 1.5,
      fontWeight: 'bold',
      letterSpacing: 3.15,
    },
  },
  '.terminal-and-brand-block': {
    marginTop: 32,
    backgroundColor: '#ffffff',
    borderRadius: 10,
    border: '1px solid #F2F2F2',
    padding: 40,
    display: 'flex',
    '.terminal-image-block': {
      img: { width: '100%' },
    },
    '.brand-image-block': {
      marginTop: 24,
    },
    '.camera-block': {
      background: '#000',
      position: 'relative',
      // QrReader
      section: { height: '100%' },
      '.camera-overlay': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        textAlign: 'center',
        '.operation-message': {
          fontSize: 16,
          fontWeight: 'bold',
          color: '#ffffff',
          letterSpacing: 2.4,
        },
        '.image-frame': {
          marginTop: 24,
          aspectRatio: '1',
          width: 'calc(100% - 160px)',
          borderRadius: 2,
          border: '3px solid #fff',
        },
      },
    },
  },
})
