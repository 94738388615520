import React, { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { css } from '@emotion/core'
import { ErrorModal } from '@/components/molecules/qr-checkin/error-modal'
import { fetchCheckinReservationsAndSales, selfCheckinGuestRoomAutoAssign } from '@/apis/aipass'
import { decodeQr } from '@/libs/qr-decode'
import QrReader from 'react-qr-reader'
import { useHistory } from 'react-router-dom'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { useTranslation } from 'react-i18next'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'

export const SmartCheckinQrReader: React.FC = props => {
  const history = useHistory()
  const { t } = useTranslation()
  const windows = useWindowSize()
  const [isError, setIsError] = useState<boolean>(false)
  const [isScanCompleted, setIsScanCompleted] = useState<boolean>(false)
  const { state, saveState } = useSelfCheckInState()

  const onScan = async data => {
    if (isScanCompleted) {
      return
    }
    if (!data) {
      setIsError(false)
      return
    }
    decodeQr(data)
      .then(async (res: any) => {
        try {
          setIsScanCompleted(true)
          const response = await fetchCheckinReservationsAndSales(state.hotelId, [], res.id)
          const qrReservationIds = response.reservations.map(item => item.reservationId)
          const selectedPaxNum = (response.reservations[0].accompany.length || 0) + 1
          selfCheckinGuestRoomAutoAssign({ hotelId: state.hotelId, reservationIds: qrReservationIds })
          saveState({
            ...state,
            selectReservation: {
              hasSmartCheckin: true,
              smartCheckinId: res.id,
              selectReservationType: 0,
              selectedReservationIds: qrReservationIds,
              selectedReservations: response.reservations,
              paxTotal: selectedPaxNum,
            },
          })
          history.push({ pathname: '/self-checkin/accommodation-info' })
        } catch (error) {
          alert(t('There is no reservation information'))
          setIsScanCompleted(false)
        }
      })
      .catch(e => {
        console.log(e)
        setIsError(true)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!state?.hotelId) {
    history.replace({ pathname: '/dashboard' })
    return <></>
  }

  return (
    <>
      {isError && <ErrorModal />}
      <div style={{ height: windows.height }} css={qrCameraWrapperStyle}>
        <QrReader showViewFinder={false} onScan={onScan} onError={() => setIsError(true)} constraints={{ facingMode: 'user' }} />
        <p css={qrCameraTitleStyle}>{t('Please align QR code within the frame to scan')}</p>
        <div css={qrCameraMaskStyle}>
          <div css={maskTopStyle} />
          <div css={cameraWrapperStyle}>
            <div css={maskLeftStyle} />
            <div css={qrCameraStyle} />
            <div css={maskRightStyle} />
          </div>
          <div css={maskBottomStyle} />
        </div>
        <SelfCheckinFooter
          goToPreviousPage={history.goBack}
          isDisabledButton={false}
          isDisabled={() => false}
          goToNextPage={() => {}}
          isNext={'next'}
        />
      </div>
    </>
  )
}

const qrCameraWrapperStyle = css({
  background: '#F2F2F2',
  minWidth: '100%',
  textAlign: 'center',
  section: {
    height: '100%',
  },
})

const qrCameraMaskStyle = css({
  width: '100%',
  height: 'calc(100vh - 90px)',
  position: 'absolute',
  top: 0,
})

const cameraWrapperStyle = css({
  display: 'flex',
  position: 'relative',
})

const maskTopStyle = css({
  background: 'rgba(0, 0, 0, 0.6)',
  height: 'calc((100vh - 90px - 525px)/2)',
})

const maskLeftStyle = css({
  background: 'rgba(0, 0, 0, 0.6)',
  height: 525,
  width: 'calc((100% - 525px)/2)',
  left: 0,
})

const qrCameraStyle = css({
  width: '525px',
  height: '525px',
  background: 'transparent',
  border: '2px solid #FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const maskRightStyle = css({
  background: 'rgba(0, 0, 0, 0.6)',
  height: 525,
  width: 'calc((100% - 525px)/2)',
  position: 'absolute',
  right: 0,
})

const maskBottomStyle = css({
  background: 'rgba(0, 0, 0, 0.6)',
  height: 'calc((100vh - 90px - 525px)/2)',
})

const qrCameraTitleStyle = css({
  fontSize: 18,
  letterSpacing: '3.6px',
  fontWeight: 'bold',
  lineHeight: '28px',
  color: 'white',
  position: 'absolute',
  width: '100%',
  zIndex: 2,
  top: 'calc((100vh - 90px - 525px)/4)',
})
