import React, { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { css } from '@emotion/core'
import { Button } from '@/components/atoms'
import { ButtonType } from '@/components/atoms/button'
import { SelfCheckinHotelInfo } from '../hotel-info'
import { selfCheckinFetchGuestRoomAssign } from '@/apis/aipass'
import dayjs from 'dayjs'
import { CheckinReceipt } from './checkin-receipt'
import { SelfCheckinCompleteState } from '@/components/pages/self-checkin/complete'

type Props = {
  hotelId: string
  checkinId: string
  basicInfo: {
    hotelNameLatin: string
    telephone: string
    frontOpenTime: string
    frontCloseTime: string
  }
  onClickTop: () => void
  state: SelfCheckinCompleteState
}
export const IssueKeyWithPrinter: React.FC<Props> = ({ state, onClickTop, ...props }) => {
  const { t } = useTranslation()
  const [roomInfo, setRoomInfo] = useState<Array<{ roomNumber: string }>>([])

  const fetchGuestRoomAssignInfo = async () => {
    await selfCheckinFetchGuestRoomAssign({
      hotelId: props.hotelId,
      checkinId: props.checkinId,
    })
      .then(res => {
        const assignRooms = props.checkinId ? Object.values(res.rooms).flat() : res.rooms
        assignRooms.sort((a, b) => {
          if (a.assignCheckinDate === b.assignCheckinDate) {
            return 0
          }
          return dayjs(a.assignCheckinDate).isBefore(dayjs(b.assignCheckinDate)) ? -1 : 1
        })
        setRoomInfo(assignRooms)
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    fetchGuestRoomAssignInfo()
  }, [])

  return (
    <div css={componentBackGroundStyle}>
      <div css={componentStyle}>
        <div className="head-line-block">
          <img src={require('@/static/images/icon_checkin_orange.svg')}></img>
          <span>{t('Check-in completed')}</span>
        </div>
        <div className="announce-block">
          <div className="checkin-message-block">
            <div className="checkin-message">
              {t('SelfCheckIn.Printed accommodation guide.')}
              <br />
              <Trans i18nKey="SelfCheckIn.Please take it from your hand and enter the room.">
                Please take it from your hand and enter <span style={{ color: '#F2A40B' }}>the room.</span>
              </Trans>
            </div>
            <div className="room-block">
              <div className="sub-text">{t('Room number')}</div>
              <div className="number-text">{roomInfo?.map(item => item.roomNumber)?.join('、') || '-'}</div>
            </div>
            <Button buttonType={ButtonType.Primary} width={194} height={48} onClick={onClickTop}>
              {t('SelfCheckIn.Confirmed')}
            </Button>
          </div>
          <div className="printer-demo-block">
            <img src={require('@/static/images/self-checkin/checkin-printer-a.png')} />
            <img src={require('@/static/images/self-checkin/checkin-printer-b.png')} />
          </div>
        </div>
      </div>
      <SelfCheckinHotelInfo
        telephone={props.basicInfo.telephone}
        frontOpenTime={props.basicInfo.frontOpenTime}
        frontCloseTime={props.basicInfo.frontCloseTime}
        position={'static'}
        usedIn="roomGuidance"
      />
      <CheckinReceipt checkinId={state.checkinId} hotelId={state.hotelId} payment={state.payment} />
    </div>
  )
}
const componentBackGroundStyle = css({
  backgroundColor: '#F2F2F2',
  padding: '0 60px',
  height: '100vh',
  alignContent: 'center',
})
const componentStyle = css({
  backgroundColor: '#FFFFFF',
  borderRadius: 10,
  whiteSpace: 'break-spaces',

  '.head-line-block': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 32,
    padding: '32px 0',
    borderBottom: '1px solid #CCCCCC',
    '> img': { width: 48 },
    '> span': {
      fontSize: 21,
      lineHeight: '21px',
      fontWeight: 'bold',
      letterSpacing: 1.05,
      color: '#F2A40B',
    },
  },
  '.announce-block': {
    display: 'flex',
    padding: 60,
    gap: 60,
    '.checkin-message-block': {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '.checkin-message': {
        font: 'normal normal bold 18px / 32px Noto Sans JP',
        letterSpacing: 2.7,
        color: '#272727',
        fontWeight: 'bold',
        textAlign: 'center',
      },
      '.room-block': {
        background: '#F5F5F5',
        border: '2px solid #CCCCCC',
        borderRadius: 10,
        textAlign: 'center',
        width: 350,
        marginTop: 24,
        fontWeight: 'bold',
        '.sub-text': {
          padding: '29px 0 11px 0',
          fontSize: 18,
          letterSpacing: 1.8,
          color: '#676767',
        },
        '.number-text': {
          paddingBottom: 38,
          fontSize: 40,
          letterSpacing: 2,
          lineHeight: '44px',
          color: '#272727',
        },
      },
      '> button': { marginTop: 32 },
    },
    '.printer-demo-block': {
      position: 'relative',
      width: '50%',
      minHeight: 374,
      display: 'flex',
      justifyContent: 'center',
      '> img': {
        position: 'absolute',
        width: 374,
        height: 374,
        opacity: 0,
        animation: 'image-switch-animation 10s infinite',
        '&:nth-of-type(1)': { animationDelay: '0s' },
        '&:nth-of-type(2)': { animationDelay: '5s' },
      },
    },
  },

  '@keyframes image-switch-animation': {
    '0%': { opacity: 0 },
    '5%': { opacity: 1 },
    '60%': { opacity: 1 },
    '65%': { opacity: 0 },
  },
})
