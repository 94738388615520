import React, { useContext, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SelfCheckinHeader } from '@/components/molecules/self-checkin/header'
import { Button } from '@/components/atoms'
import { ButtonType } from '@/components/atoms/button'
import { SupportQr } from '@/components/organisms/self-checkin/payment/support-qr'
import { translateObject } from '@/libs/text'
import dayjs from 'dayjs'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { LoaderContextCreator } from '@/contexts/loader'
import { useGuestAppUrl } from '@/hooks/use-guest-app-url'

type SalesType = {
  id: string
  salesDate: string
  salesSubjectName: string
  salesSubjectNameEn: string
  salesSubjectNameKo: string
  salesSubjectNameZh: string
  salesSubjectNameZhTw: string
  salesPrice: number
}
type PriceSummaryType = {
  totalPrice: number
  totalPayment: number
  totalTaxPrice: number
  totalDiscount: number
  totalAmount: number
}

export const TerminalPaymentForm: React.FC = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [reservations, setReservations] = useState<Array<{ reservationId: string; sales: Array<SalesType> }>>()
  const [priceSummary, setPriceSummary] = useState<PriceSummaryType>()
  const { state, doCheckIn } = useSelfCheckInState()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const { mypageUrl, homePageUrl } = useGuestAppUrl({
    hotelId: state.hotelId,
    plugin: state.setting.plugin,
  })

  const initializeState = async () => {
    setReservations(state.confirm?.payment?.usage.reservations)
    setPriceSummary(state.confirm?.payment?.usage)
  }

  const doCheckinWithoutPayment = async () => {
    try {
      setIsLoading(true)
      await doCheckIn({
        state,
        language: i18n.language,
        guestAppUrl: { mypageUrl, homePageUrl },
      })
      history.replace({ pathname: '/self-checkin/issue-room-key' })
    } catch (e) {
      console.error(e)
      alert(t('Please complete the procedure at the front desk'))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    initializeState()
  }, [])

  return (
    <>
      <SelfCheckinHeader goToPreviousPage={() => history.goBack()} title={t('Payment details')} />
      <div css={containerStyle}>
        <div>
          <div css={salesSubjectBlock} style={{ width: '60%' }}>
            <div className="block-title">利用明細</div>
            <div className="reserve-and-sales-block">
              {reservations?.map((r, i) => (
                <div key={r.reservationId}>
                  <div className="reservation-row">
                    <div>{`予約${i + 1}`}</div>
                    <div>予約ID : {r.reservationId}</div>
                  </div>
                  <div>
                    {r.sales.map(s => (
                      <div key={s.id} className="sales-row">
                        <div>
                          <div>{dayjs(s.salesDate).format('MM/DD')}</div>
                          <div>{translateObject(s, 'salesSubjectName', i18n.language)}</div>
                        </div>
                        <div>¥{s.salesPrice.toLocaleString()}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div style={{ width: '40%', display: 'flex', flexDirection: 'column', gap: 16 }}>
            <div css={amountBlock}>
              <div className="detail-block">
                <div className="price-row">
                  <div>ご利用金額</div>
                  <div className="-price">¥{priceSummary?.totalPrice.toLocaleString()}</div>
                </div>
                <div className="price-row">
                  <div>割引</div>
                  <div className="-price">¥{priceSummary?.totalDiscount.toLocaleString()}</div>
                </div>
              </div>
              <div className="total-block">
                <div className="price-row">
                  <div>ご請求金額</div>
                  <div className="-price">¥{priceSummary?.totalAmount.toLocaleString()}</div>
                </div>
              </div>
            </div>
            <div css={paymentMethodBlock}>
              <div style={{ display: 'flex', gap: 16, paddingTop: 18, flexWrap: 'wrap' }}>
                <Button
                  buttonType={ButtonType.Primary}
                  width={
                    state.setting.paymentTerminal?.isEnabledCash && state.setting.paymentTerminal?.isEnabledQr
                      ? 'calc(50% - 8px)'
                      : '100%'
                  }
                  height={53}
                  borderRadius={10}
                  onClick={() => history.push({ pathname: '/self-checkin/payment/terminal/card' })}
                >
                  {t('クレジット')}
                </Button>
                <Button
                  buttonType={ButtonType.Primary}
                  width="calc(50% - 8px)"
                  height={53}
                  borderRadius={10}
                  onClick={() => history.push({ pathname: '/self-checkin/payment/terminal/card', search: 'unionPay=1' })}
                >
                  {t('銀聯カード')}
                </Button>
                {state.setting.paymentTerminal?.isEnabledQr && (
                  <Button
                    buttonType={ButtonType.Primary}
                    width="calc(50% - 8px)"
                    height={53}
                    borderRadius={10}
                    onClick={() => history.push({ pathname: '/self-checkin/payment/terminal/qr' })}
                  >
                    {t('QR決済')}
                  </Button>
                )}
                {state.setting.paymentTerminal?.isEnabledCash && (
                  <Button
                    buttonType={ButtonType.Primary}
                    width="calc(50% - 8px)"
                    height={53}
                    borderRadius={10}
                    onClick={doCheckinWithoutPayment}
                  >
                    {t('フロントで現金支払い')}
                  </Button>
                )}
              </div>
              <div className="credit-block">
                <div className="payment-title">対応クレジット</div>
                <img src={require('@/static/images/self-checkin-payment/terminal/logo_card01.png')} />
              </div>
              {state.setting.paymentTerminal?.isEnabledQr && <SupportQr className="brand-image-block" />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const containerStyle = css({
  backgroundColor: '#f2f2f2',
  // add header size (=80)
  paddingTop: 32 + 80,
  paddingBottom: 32,
  width: '100%',
  '*': {
    fontFamily: 'Noto Sans JP',
    color: '#272727',
  },
  '> div': {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 24,
    height: '100%',
    maxWidth: 880,
  },
})
const blockLayout = css({
  backgroundColor: '#ffffff',
  borderRadius: 10,
})
const salesSubjectBlock = css(blockLayout, {
  '.block-title': {
    padding: '10px 24px',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: 2.4,
    lineHeight: '19px',
  },
  '.reserve-and-sales-block': {
    overflow: 'auto',
    // sub block title (=39px)
    height: 'calc(100% - 39px)',
  },
  '.reservation-row': {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#F5F5F5',
    padding: '6px 24px',
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: 1.8,
    lineHeight: '19px',
  },
  '.sales-row': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    fontSize: 16,
    letterSpacing: 2.4,
    lineHeight: '19px',
    borderBottom: '1px solid #F2F2F2',
    'div:first-of-type': { display: 'flex', gap: 34 },
  },
})
const amountBlock = css(blockLayout, {
  '.price-row': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 12,
  },
  '.detail-block': {
    padding: '6px 24px 18px 24px',
    fontSize: 14,
    letterSpacing: 2.1,
    '.-price': { fontSize: 16, letterSpacing: 2.4 },
  },
  '.total-block': {
    borderTop: '1px solid #CCCCCC',
    padding: '9px 24px 24px 24px',
    fontWeight: 'bold',

    fontSize: 14,
    letterSpacing: 2.1,
    '.-price': { fontSize: 20, letterSpacing: 3 },
  },
})
const paymentMethodBlock = css(blockLayout, {
  padding: 24,
  '.payment-title': {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 1.8,
    color: '#676767',
    paddingTop: 8,
    lineHeight: 1.5,
  },
  '.brand-image-block': { marginTop: 8 },
  '.credit-block': {
    marginTop: 22,
    border: '1px solid #CCCCCC',
    borderRadius: 10,
    img: {
      width: '100%',
      padding: '14px 8px',
    },
  },
})
