import React, { useState, useContext, useEffect } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { CheckinTabContainer } from '@/components/organisms/settings/checkin-tab-container'
import { AccountContext } from '@/contexts/account'
import { useHistory } from 'react-router-dom'
import { Button } from '@/components/atoms'
import * as Api from '@/apis/aipass'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'
import { ToggleButton } from '@/components/molecules/settings/toggle-button'
import { fetchTimeSelection, updateTimeSelectionViewState } from '@/apis/aipass'
import { TimeSelectionIndex } from '@/models/custom-checkin-time-selection'
import { EditFooter } from '@/components/organisms/edit-footer'

export const TimeSelection: React.FC = () => {
  const [timeSelections, setTimeSelections] = useState<TimeSelectionIndex[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [deleteIds, setDeleteIds] = useState<string[]>([])

  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const history = useHistory()

  const toggleViewStatus = async (changedItem: TimeSelectionIndex) => {
    setIsLoading(true)
    await updateTimeSelectionViewState(changedItem.id, !changedItem.isVisible)
    await _fetchCustomCheckin()
    setIsLoading(false)
  }

  const bulkDelete = async () => {
    setIsLoading(true)
    setDeleteIds([])
    await Api.deleteTimeSelection(deleteIds)
    await _fetchCustomCheckin()
    setIsLoading(false)
  }
  const _fetchCustomCheckin = async () => {
    const res = await fetchTimeSelection()
    setTimeSelections(res || [])
  }

  const onCloseFooter = () => {
    setDeleteIds([])
  }

  useEffect(() => {
    if (account && account?.hotel) {
      _fetchCustomCheckin()
    }
  }, [account])

  const footerContent = !!deleteIds.length ? <EditFooter onSave={() => bulkDelete()} onCancel={() => onCloseFooter()} /> : undefined
  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <TabContainer tabComponent={<CheckinTabContainer currentTab="Time selection" />}>
        <div style={{ padding: '20px 32px' }}>
          <Button
            width={107}
            height={32}
            buttonType={1}
            marginRight={8}
            icon={require('@/static/images/plus-white.svg')}
            onClick={() => history.push('/setting/checkin/time-selection/new')}
          >
            {t('Add new')}
          </Button>
        </div>
        <div css={tableStyle}>
          <table>
            <thead css={theadStyle}>
              <tr>
                <th css={titleStyle}>{t('Title')}</th>
                <th css={spaceTypeStyle}>{t('Space type')}</th>
                <th css={keywordGroupStyle}>{t('keyword group')}</th>
                <th css={keywordsToDisplayStyle}>{t('Keywords to display')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {timeSelections
                .filter(timeSelection => !deleteIds.includes(timeSelection.id))
                .map(timeSelection => (
                  <tr
                    key={timeSelection.id}
                    style={{ height: 64, cursor: 'pointer', fontSize: 14, fontWeight: 'bold' }}
                    onClick={() => history.push(`/setting/checkin/time-selection/${timeSelection.id}`)}
                  >
                    <td css={titleStyle}>{timeSelection.title}</td>
                    <td css={spaceTypeStyle}>{timeSelection.spaceTypeName}</td>
                    <td css={keywordGroupStyle}>{timeSelection.planKeyWordGroupName}</td>
                    <td css={keywordsToDisplayStyle}>{timeSelection.planKeywords.map(keyword => keyword.keyword).join(', ')}</td>
                    <td style={{ paddingRight: 32 }}>
                      <div css={listButtonStyle}>
                        <ToggleButton value={timeSelection.isVisible} onChange={() => toggleViewStatus(timeSelection)} />
                        <DeleteIcon onClick={() => setDeleteIds([...deleteIds, timeSelection.id])} style={{ marginLeft: 25 }} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </TabContainer>
    </SettingsLayout>
  )
}

const tableFooterHeight = 36
const theadStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
})
const tableStyle = css({
  height: `calc(100% - ${tableFooterHeight + 48}px)`,
  overflow: 'auto',
  table: {
    width: '100%',
  },
  'th, td': {
    paddingLeft: 32,
    whiteSpace: 'nowrap',
    height: '100%',
    verticalAlign: 'middle',
    ':first-of-type': {
      paddingLeft: 62,
    },
  },
  th: {
    paddingBlock: 10,
    background: '#F2F2F2',
    color: '#676767',
  },
  tbody: {
    tr: {
      borderBottom: '1px solid #f2f2f2',
    },
  },
})
const titleStyle = css({
  width: 150,
})
const spaceTypeStyle = css({
  width: 200,
})
const keywordGroupStyle = css({
  width: 200,
})
const keywordsToDisplayStyle = css({
  width: 200,
})

const listButtonStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
})
