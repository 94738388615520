export enum DayOfApplicableType {
  EveryDay = 'everyDay',
  FirstNight = 'firstNight',
  LastDay = 'lastDay',
}

export enum DayOfUseType {
  AppointedDay = 'appointedDay',
  NextDay = 'nextDay',
}

export enum StockPerReservationType {
  Single = 'single',
  NumOfPeople = 'numOfPeople',
}

export interface PlanKeywords {
  keyword: string
}

export interface TimeSelectionIndex {
  id: string
  title: string
  spaceTypeName: string
  planKeyWordGroupName: string
  planKeywords: PlanKeywords[]
  isVisible: boolean
}

export interface TimeSelectionDetail {
  id: string
  title: string
  spaceId: string
  planKeyWordGroupId: string
  description: string
  rawDescription: string
  planKeywords: PlanKeywords[]
  dayOfApplicable: DayOfApplicableType
  dayOfUse: DayOfUseType
  stockPerReservation: StockPerReservationType
  isRequired: boolean
}

export interface TimeSelectionInput {
  spaceId: string
  planKeyWordGroupId: string
  planKeywords: { keyword: string }[]
  title: string
  description: string
  rawDescription: string
  dayOfApplicable: DayOfApplicableType
  dayOfUse: DayOfUseType
  stockPerReservation: StockPerReservationType
  isRequired: boolean
}
export type TimeSelectionSubmitData = Omit<TimeSelectionInput, 'planKeywords'> & { planKeywords: string[] }
