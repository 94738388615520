import React from 'react'
import { css, CSSObject, SerializedStyles } from '@emotion/core'
import { RadioButton } from '../atoms/radio-button'
import uuid from 'react-uuid'

type RadioGroupProps<T> = {
  value?: T
  name?: string
  items: {
    id?: string
    value: T
    label: string
  }[]
  onChange: (e: React.ChangeEvent<HTMLInputElement & { value: T }>) => void
  groupCss?: CSSObject | SerializedStyles
  itemWrapperCss?: CSSObject | SerializedStyles
  itemCss?: CSSObject | SerializedStyles
  style?: {
    direction?: 'column' | 'row' | 'wrap'
    radioSize?: number
    labelMargin?: number
  }
}

export const RadioGroupField = <T,>({ value, groupCss, itemWrapperCss, items, itemCss, name, style, onChange }: RadioGroupProps<T>) => {
  return (
    <div css={[groupDefaultStyle, groupCss]} style={{ flexFlow: style?.direction || 'row' }}>
      {items.map((item, index) => (
        <div key={index} css={[itemWrapperCss]}>
          <RadioButton<T>
            id={item?.id || uuid()}
            name={name}
            checked={item.value === value}
            item={item}
            onChange={onChange}
            style={style}
            itemCss={itemCss}
          />
        </div>
      ))}
    </div>
  )
}

const groupDefaultStyle = css({
  display: 'flex',
  overflow: 'auto',
  alignSelf: 'center',
})
