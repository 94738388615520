import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import parse from 'html-react-parser'
import { SelfCheckinHeader } from '@/components/molecules'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { CustomCheckin } from '@/components/organisms/self-checkin/custom-checkin'
import { checkValidateGuidesRequired, addValidateGuidesRequired, changeValidateGuidesRequired, Guide } from '@/utils/guide'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { NationalityType } from '@/models/self-checkin/accommodation-info'
import { TimeSelection } from '@/components/organisms/self-checkin/time-selection'
import { fetchTimeSelectionSpace } from '@/apis/aipass'
import { SelectedTime, SpaceAssign, TimeSelectionType } from '@/models/self-checkin/custom-checkin'
import dayjs from 'dayjs'
import { StockPerReservationType } from '@/models/custom-checkin-time-selection'
import { isEmpty } from 'lodash'

export const CheckinCustomize: React.FC<{}> = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [guides, setGuides] = useState<Guide[]>([])
  const [validGuides, setValidGuides] = useState<any>([])
  const [timeSelections, setTimeSelections] = useState<TimeSelectionType[]>([])
  const [selectedTimes, setSelectedTimes] = useState<SelectedTime>({})
  const [options, setOptions] = useState<any>()
  const [selectedInputValue, setSelectedInputValue] = useState<any>([])
  const history = useHistory()
  const { state, saveState } = useSelfCheckInState()

  const getTitle = guide => {
    if (lang === 'ja') {
      return parse(guide.jpTitle)
    }
    if (lang === 'en') {
      return parse(guide.enTitle)
    }
    if (lang === 'ko') {
      return parse(guide.koTitle)
    }
    if (lang === 'zh') {
      return parse(guide.zhTitle)
    }
  }

  const getContent = guide => {
    if (lang === 'ja') {
      return parse(guide.jpContent)
    }
    if (lang === 'en') {
      return parse(guide.enContent)
    }
    if (lang === 'ko') {
      return parse(guide.koContent)
    }
    if (lang === 'zh') {
      return parse(guide.zhContent)
    }
  }

  const getOption = guide => {
    if (lang === 'ja') {
      return guide.jpInputValue
    }
    if (lang === 'en') {
      return guide.enInputValue
    }
    if (lang === 'ko') {
      return guide.koInputValue
    }
    if (lang === 'zh') {
      return guide.zhInputValue
    }
  }

  const filterUniqueValueById = selectedInputValue => {
    const customCheckinIds = selectedInputValue.map(v => {
      return v.customCheckinId
    })
    return selectedInputValue.filter((v, index) => {
      // keep the last choice
      return customCheckinIds.lastIndexOf(v.customCheckinId) === index
    })
  }

  const onChangeState = (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>, customCheckinId) => {
    if (!e.target.value) {
      const addValidateGuides = addValidateGuidesRequired(customCheckinId, guides)
      if (addValidateGuides.length > 0) {
        const errorMessage = document.getElementById(addValidateGuides[0]['customCheckinId'] + '-error')
        if (errorMessage) {
          errorMessage.style.visibility = errorMessage ? 'visible' : ''
        }
      }
      setValidGuides(addValidateGuides.concat(validGuides))
    } else {
      const changeValidateGuides = changeValidateGuidesRequired(customCheckinId, validGuides)
      const errorMessage = document.getElementById(customCheckinId + '-error')
      if (errorMessage) {
        errorMessage.style.visibility = errorMessage.style.visibility === 'visible' ? 'hidden' : ''
      }
      setValidGuides(changeValidateGuides)
    }
    const value = e.target.value
    const name = e.target.name
    const newSlectedInputValue = [...selectedInputValue, { customCheckinId: customCheckinId, [name]: value }]
    setSelectedInputValue(filterUniqueValueById(newSlectedInputValue))
  }

  // Replace form content with input content, not filled in with -
  const replaceInputValue = () => {
    const _guides = [...guides]
    _guides.map(guide => {
      selectedInputValue.map(v => {
        if (v.customCheckinId === guide.customCheckinId) {
          // When you reselect Please select
          if (v.value === '') {
            guide.selectedAnswer = '-'
          } else {
            guide.selectedAnswer = v.value
          }
        }
      })
      // when not choosing
      if (!guide.selectedAnswer) {
        guide.selectedAnswer = '-'
      }
    })
    setGuides(_guides || [])
  }

  const isFilledRequireTimeSelection = () => {
    const requiredTimeSelections = timeSelections.filter(timeSelection => timeSelection.isRequired)
    if (requiredTimeSelections.length === 0) {
      return false
    }

    return requiredTimeSelections.some(timeSelection => {
      return Object.keys(timeSelection.availabilityTime).length !== selectedTimes[timeSelection.id]?.length
    })
  }

  const getTimeSelection = async () => {
    if (!state.selectReservation || state.selectReservation?.selectedReservationIds.length === 0) {
      return
    }

    await fetchTimeSelectionSpace(state.hotelId, state.selectReservation.selectedReservationIds)
      .then(res => {
        setTimeSelections(res || [])
      })
  }

  const changeSpaceTime = ({ timeSelectionId, selectedTime, day }: { timeSelectionId: string; selectedTime: string; day: string }) => {
    if (timeSelectionId in selectedTimes) {
      const newSelect = selectedTimes[timeSelectionId]
      const index = newSelect.findIndex(select => select.day === day)
      if (index !== -1) {
        newSelect.splice(index, 1)
      }
      newSelect.push({ time: selectedTime, day })
      setSelectedTimes({
        ...selectedTimes,
        [timeSelectionId]: newSelect,
      })
    } else {
      setSelectedTimes({
        ...selectedTimes,
        [timeSelectionId]: [{ time: selectedTime, day }],
      })
    }
  }

  const convertTimeToMinutes = timeStr => {
    const [hours, minutes] = timeStr.split(':').map(Number)
    return hours * 60 + minutes
  }

  const isDisabled = (): boolean => {
    const isRequiredGuides = guides.length === 0 || validGuides.length > 0
    return isRequiredGuides || isFilledRequireTimeSelection()
  }

  const nextPath = () => {
    const isForeign = state.accommodationInfo?.nationality !== NationalityType.Jp
    return `/self-checkin/identity-verify/${isForeign ? 'upload-passport' : 'upload-license'}`
  }

  const onSubmit = () => {
    replaceInputValue()
    const nav = t('Information from the facility')
    const notes = guides.map(guide => {
      if (guide.inputType !== 'none') {
        return `${guide.jpTitle}:${guide.selectedAnswer}`
      }
    })
    const filteredNotes = notes.filter(v => v)
    let checkinMemo: string = ''
    if (filteredNotes.length !== 0) {
      checkinMemo = [nav, ...filteredNotes].join('\n')
    } else {
      checkinMemo = ''
    }

    const assignSpaces: SpaceAssign[] = []
    Object.keys(selectedTimes).forEach(timeSelectionId => {
      const timeSelection = timeSelections.find(timeSelection => timeSelection.id === timeSelectionId)
      if (!timeSelection) {
        return
      }
      const reservation = state?.selectReservation?.selectedReservations.find(reservation => reservation.reservationId === timeSelection?.reservationId)
      if (!reservation) {
        return
      }

      selectedTimes[timeSelectionId].forEach(({ time, day }) => {
        const usageFrom = dayjs(`${day} ${time}`)
        const usageTo = timeSelection.minUsageTime
          ? usageFrom.add(convertTimeToMinutes(timeSelection.minUsageTime), 'minute')
          : usageFrom.add(timeSelection.usageInterval, 'minute')
        const spaceAssign: SpaceAssign = {
          pax: reservation.paxTotal,
          reservationUserName: reservation.guestName || reservation.userName || '',
          reservationId: timeSelection.reservationId,
          spaceId: timeSelection.spaceId,
          usageFrom: usageFrom.format('YYYY-MM-DD HH:mm:ss'),
          usageTo: usageTo.format('YYYY-MM-DD HH:mm:ss'),
          spaceIndex: timeSelection.stockPerReservation === StockPerReservationType.Single ? 1 : reservation.paxTotal,
        }

        assignSpaces.push(spaceAssign)
      })
    })
    saveState({
      ...state,
      checkinCustomize: {
        guides,
        checkinMemo,
      },
      timeSelection: {
        assignSpaces,
        selectedTime: selectedTimes,
      }
    })
    history.push({ pathname: nextPath() })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (state.selectReservation?.hasSmartCheckin || !state.setting.customCheckin.length) {
      history.replace({ pathname: nextPath() })
      return
    }
    if (state.checkinCustomize) {
      setGuides(state.checkinCustomize.guides)
      return
    }
    const isRequiredGuides = checkValidateGuidesRequired(state.setting.customCheckin)
    setValidGuides(isRequiredGuides)
    setGuides(state.setting.customCheckin)
    if (state.timeSelection) {
      setSelectedTimes(state.timeSelection.selectedTime)
    }
  }, [])

  useEffect(() => {
    if (guides) {
      setOptions({})
      guides.map((guide, index) => {
        const translatedOption = getOption(guide)
        setOptions(prevOptions => ({
          ...prevOptions,
          [index]: translatedOption,
        }))
      })
    }
  }, [guides, lang])

  useEffect(() => {
    getTimeSelection()
  }, [])

  if (!state?.hotelId) {
    history.replace({ pathname: '/dashboard' })
    return <></>
  }
  return (
    <div css={containerStyle}>
      <SelfCheckinHeader goToPreviousPage={history.goBack} title={t('Guidance')} />
      <div css={mainStyle}>
        <div css={containerWrapperStyle}>
          {guides?.map((guide, index) => (
            <CustomCheckin
              key={index}
              index={index}
              guide={guide}
              options={options}
              selectedInputValue={selectedInputValue}
              onChangeState={onChangeState}
              getTitle={getTitle}
              getContent={getContent}
            />
          ))}
        </div>
        {!isEmpty(timeSelections) && (
          <div css={timeSelectionStyle}>
            {timeSelections.map(timeSelection => (
              <TimeSelection timeSelection={timeSelection} onChange={changeSpaceTime} />
            ))}
          </div>
        )}
        <SelfCheckinFooter isDisabled={isDisabled} goToNextPage={onSubmit} isNext={'next'} />
      </div>
    </div>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  fontFamily: 'Noto Sans JP',
  backgroundColor: '#F2F2F2',
  minHeight: '100vh',
})

const mainStyle = css({
  padding: '112px 0',
})

const containerWrapperStyle = css({
  margin: 'auto',
})

const timeSelectionStyle = css({
  maxWidth: 874,
  padding: 40,
  marginInline: 'auto',
  marginTop: 24,
  background: '#fff',
  borderRadius: 10,
  '> div:not(:first-child)': {
    marginTop: 40,
    paddingTop: 40,
    borderTop: '1px solid #F2F2F2',
  },
})
