import React, { useContext, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { CheckinPaymentDetail } from '@/components/organisms/self-checkin/payment/checkin-payment-detail'
import { selfCheckinHandlePayment } from '@/apis/aipass'
import { CASH, CREDIT_CARD, paymentMethodOptions as defaultPaymentMethodOptions } from '@/models/self-checkin/payment-method'
import { useForm } from 'react-hook-form'
import { CheckinPaymentMethod } from './payment-method-select'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { useCardToken } from '@/hooks/use-card-token'
import { useGuestAppUrl } from '@/hooks/use-guest-app-url'
import { LoaderContextCreator } from '@/contexts/loader'

export type CardInfo = {
  cardNumber: string
  expiredMonth: string
  expiredYear: string
  holderName: string
  cvc: string
}

export const ManualPaymentForm: React.FC = () => {
  const { state, doCheckIn } = useSelfCheckInState()
  const history = useHistory()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(state.setting.payment[0])
  const [paymentMethodOptions] = useState(defaultPaymentMethodOptions)
  const { isLoading, setIsLoading } = useContext(LoaderContextCreator())

  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { makeCardToken } = useCardToken()
  const cardForm = useForm<CardInfo>({
    mode: 'onBlur',
    defaultValues: {
      cardNumber: '',
      expiredMonth: '',
      expiredYear: '',
      holderName: '',
      cvc: '',
    },
  })
  const { mypageUrl, homePageUrl } = useGuestAppUrl({ hotelId: state.hotelId, plugin: state.setting.plugin })

  const onSubmit = async () => {
    if (!validateCardInfo) {
      return
    }
    try {
      setIsLoading(true)
      const reservationIds = state.selectReservation?.selectedReservationIds || []
      let paymentResult: any = undefined
      if (selectedPaymentMethod === CREDIT_CARD) {
        try {
          const cardInfo = cardForm.getValues()
          const cardToken = await makeCardToken(state.hotelId, {
            cardNumber: cardInfo.cardNumber.replaceAll(' ', ''),
            cardExpire: `${cardInfo.expiredMonth}/${cardInfo.expiredYear}`,
            securityCode: cardInfo.cvc,
            cardHolder: cardInfo.holderName,
          })
          paymentResult = await selfCheckinHandlePayment(state.hotelId, reservationIds, cardToken)
        } catch (error) {
          setIsLoading(false)
          console.warn(error)
          alert(t('Your payment was declined by your card issuer Please connect your card issuer'))
          return
        }
      }
      await doCheckIn({
        state,
        language,
        guestAppUrl: { mypageUrl, homePageUrl },
        selectedPaymentMethod,
        paymentResult: paymentResult
          ? {
              orderId: paymentResult.processResult.orderId,
              cardBrand: paymentResult.processResult.cardBrand,
              amount: paymentResult.processResult.amount || 0,
              totalAmountAt10Per: paymentResult.processResult.totalAmountAt10Per || 0,
              totalAmountAt8Per: paymentResult.processResult.totalAmountAt8Per || 0,
              totalTaxAt10Per: paymentResult.processResult.totalTaxAt10Per || 0,
              totalTaxAt8Per: paymentResult.processResult.totalTaxAt8Per || 0,
            }
          : undefined,
      })
      history.replace({ pathname: '/self-checkin/issue-room-key' })
    } catch (e) {
      console.error(e)
      alert(t('Please complete the procedure at the front desk'))
    } finally {
      setIsLoading(false)
    }
  }

  const validateCardInfo = useMemo(() => {
    if (selectedPaymentMethod === CASH) {
      return true
    }
    return cardForm.formState.isValid
  }, [selectedPaymentMethod, cardForm.formState.isValid])

  return (
    <>
      <div css={containerStyle}>
        <div css={wrapper}>
          {state.confirm?.payment?.usage.reservations.map(
            (reservation, index) =>
              !!reservation.sales.length && (
                <div css={wrapperStyle} key={index}>
                  <CheckinPaymentDetail reservation={reservation} index={index} />
                </div>
              ),
          )}
        </div>
        <div css={wrapper}>
          <div css={commonContainerStyle}>
            <div css={amountStyle}>
              <p css={amountItem}>{t('Amount billed')}</p>
              <p css={amountValue}>¥{state.confirm?.payment?.usage.totalAmount}</p>
            </div>
            <p css={taxStyle}>
              ({t('Tax')}：¥{state.confirm?.payment?.usage.totalTaxPrice})
            </p>
          </div>
        </div>

        <div css={wrapper}>
          <div css={paymentStyle}>
            <CheckinPaymentMethod
              paymentMethodOptions={paymentMethodOptions}
              onChangePaymentMethod={e => {
                cardForm.reset({
                  cardNumber: '',
                  expiredMonth: '',
                  expiredYear: '',
                  holderName: '',
                  cvc: '',
                })
                setSelectedPaymentMethod(e.target.value)
              }}
              selectedPaymentMethod={selectedPaymentMethod}
              paymentSetting={state.setting.payment}
              cardForm={cardForm}
            />
          </div>
        </div>
      </div>
      <SelfCheckinFooter isDisabled={() => isLoading || !validateCardInfo} goToNextPage={onSubmit} isNext={'submit'} />
    </>
  )
}

const containerStyle = css({
  backgroundColor: '#f2f2f2',
  paddingTop: '86px',
  paddingBottom: '106px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  justifyContent: 'flex-start',
  '*': {
    fontFamily: 'Noto Sans JP',
    color: '#272727',
  },
})

const wrapperStyle = css({
  background: 'transparent',
  borderRadius: '10px',
  margin: '27px auto 0',
  maxWidth: 872,
})

const paymentStyle = css({
  maxWidth: 872,
  margin: '0 auto',
})

const commonContainerStyle = css({
  backgroundColor: '#fff',
  textAlign: 'left',
  padding: '30px 32px 30px 32px',
  borderRadius: '10px',
  margin: '16px auto 0',
  maxWidth: 872,
})

const amountStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const amountItem = css({
  fontSize: '16px',
  color: '#d68e0f',
  fontWeight: 'bold',
  letterSpacing: '2.7px',
})
const amountValue = css(amountItem, {
  fontSize: '18px',
})

const taxStyle = css({
  textAlign: 'right',
  width: '100%',
  color: '#676767',
  fontSize: '14px',
  letterSpacing: '2.1px',
  marginTop: '2px',
  lineHeight: '19px',
})
const wrapper = css({
  padding: '0 75px 0 74px',
})
